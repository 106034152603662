import React, { useState, useEffect, useCallback } from 'react';
import Calendar from '../components/Calendar';
import Button from '../components/Button';
import './CalendarPage.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { useNavigate } from 'react-router-dom';

registerLocale('fr', fr);
setDefaultLocale('fr');

function CalendarPage() {
  const [resources, setResources] = useState([]);
  const [date, setDate] = useState(new Date());
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchResources = async () => {
      const response = await fetch("/resources");
      const data = await response.json();
      setResources(data);
    };

    fetchResources();
  }, []);

  const handleSelect = async (info) => {

    console.log(`Selected from ${info.startStr} to ${info.endStr} on resource ${info.resource.id}`);

    const diff = new Date(info.endStr) - new Date(info.startStr);
    const diffMin = diff / (1000 * 60);
    const hours = Math.floor(diffMin / 60);
    const minutes = diffMin % 60;

    const price = info.resource.extendedProps.price;
    let cost = 0;
    let hoursTemp = hours;
    
    if (price !== "À déterminer") {
      if (minutes !== 0) {
        cost += price[0] / 2;
      }
      if (hoursTemp > 6) {
        cost += price[2];
        hoursTemp = 0;
      }
      if (hoursTemp > 3) {
        cost += price[1];
        hoursTemp -= 4;
      }
      if (hoursTemp > 0) {
        cost += price[0] * hoursTemp;
      }
    } else {
      cost = "À déterminer"
    }

    const appointmentInfo = {
      start: info.startStr,
      end: info.endStr,
      resource_id: info.resource.id,
      name: info.resource.title,
      hours : hours,
      minutes : minutes,
      cost : cost
    };

    setSelectedAppointment(appointmentInfo);

    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(appointmentInfo)
      };

      const response = await fetch("/save_selected_data", options);

      if (response.status === 200) {
        console.log("Data saved successfully");
      } else {
        console.error(`Failed to save data: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error saving data:', error.message);
    }
  };

  const handleDateChange = useCallback((date) => {
    setDate(date);
  }, []);

  const handleCalendarDateChange = useCallback((newDate) => {
    setDate(newDate);
  }, []);

  return (
    <div className="calendar-page">
      <header className="header">
        <h1>Planifiez votre Réservation</h1>
        <p className="message">
          Cliquez et glissez avec votre souris pour choisir un créneau horaire pour la réservation de la salle.
        </p>
      </header>
      <main className="main-content">
        <div className="calendar-wrapper">
          <div className="datepicker-container">
            <DatePicker
              selected={date}
              onChange={handleDateChange}
              className="datepicker"
              dateFormat="dd/MM/yyyy"
              locale={'fr'}
              inline
            />
          </div>
          <div className="calendar-container">
            <Calendar
              handleSelect={handleSelect}
              resources={resources}
              date={date}
              onDateChange={handleCalendarDateChange}
            />
          </div>
        </div>
        {selectedAppointment && (
          <div className="appointment-details">
            <h2>Détails du Rendez-vous</h2>
            <p><strong>Date :</strong> {new Date(selectedAppointment.start).toLocaleDateString('fr-FR')}</p>
            <p><strong>Heure de début :</strong> {new Date(selectedAppointment.start).toLocaleTimeString('fr-FR')}</p>
            <p><strong>Heure de fin :</strong> {new Date(selectedAppointment.end).toLocaleTimeString('fr-FR')}</p>
            <p><strong>Salle :</strong> {selectedAppointment.name}</p>
            <p><strong>Durée :</strong> {selectedAppointment.hours}h{(selectedAppointment.minutes === 0) ? '' : selectedAppointment.minutes}</p>
            <p><strong>Prix :</strong> {selectedAppointment.cost}{(selectedAppointment.cost === "À déterminer") ? '' : '€'}</p>
            <div className="button-container">
              <Button buttonDisabled={false} text={"Suivant"} type={"button"} onClick={() => navigate("/form")} />
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default CalendarPage;
