import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import './IntermediatePage.css';

function IntermediatePage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [parameters, setParameters] = useState({});

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const paramsTab = {};
        params.forEach((value, key) => {
            if (key !== 'ts' && key !== 'hmac') {
                paramsTab[key] = atob(value);
            }
        })
        const diff = new Date(paramsTab['end']) - new Date(paramsTab['start']);
        const diffMin = diff / (1000 * 60);
        const hours = Math.floor(diffMin / 60);
        const minutes = diffMin % 60;
        paramsTab['hours'] = hours;
        paramsTab['minutes'] = minutes;
        setParameters(paramsTab);
    }, [location.search]);

    const handleRedirect = () => {
        const params = new URLSearchParams(location.search);
        const paramsStr = params.toString();
        navigate(`/confirm?${paramsStr}`);
    };

    const responseCheckbox = (check) => {
        return check === 'True' ? "Oui" : "Non";
    };

    return (
        <div className="intermediate-page">
            <div className="intermediate-message-container">
                <h2>Récapitulatif du rendez-vous:</h2>
                <p><strong>Client :</strong> {parameters.email}</p>
                <p><strong>Date :</strong> {new Date(parameters.start).toLocaleDateString('fr-FR')}</p>
                <p><strong>Heure de début :</strong> {new Date(parameters.start).toLocaleTimeString('fr-FR')}</p>
                <p><strong>Heure de fin :</strong> {new Date(parameters.end).toLocaleTimeString('fr-FR')}</p>
                <p><strong>Salle :</strong> {parameters.resource_id}</p>
                <p><strong>Durée :</strong> {parameters.hours}h{(parameters.minutes === 0) ? '' : parameters.minutes}</p>
                <p><strong>Prix :</strong> {parameters.cost}{(parameters.cost === "À déterminer") ? '' : '€'}</p>
                <p><strong>Petit Déjeuner :</strong> {responseCheckbox(parameters.petitdej)}</p>
                <p><strong>Collation :</strong> {responseCheckbox(parameters.collation)}</p>
                <p><strong>Déjeuner :</strong> {responseCheckbox(parameters.repas)}</p>
                <p><strong>Badge :</strong> {responseCheckbox(parameters.badge)}</p>
                <p><strong>Commentaire :</strong> {(parameters.comment)}</p>
                <Button
                    text="Finaliser la réservation"
                    type="button"
                    onClick={handleRedirect}
                />
            </div>
        </div>
    );
    
}

export default IntermediatePage;
