// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preconfirm-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f4f4f9;
    font-family: 'Arial', sans-serif;
    padding: 15px;
    box-sizing: border-box;
}

.preconfirm-message-container {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 100%;
    margin-bottom: 1.5rem;
}

.preconfirm-message-container h2.success {
    color: #4caf50;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
}

.preconfirm-message-container h2.error {
    color: #f44336;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
}

.preconfirm-message-container p {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
    margin-bottom: 1.5rem;
}

.loader {
    font-size: 1.2rem;
    color: #666;
    text-align: center;
    margin: 2rem 0;
}

@media screen and (max-width: 768px) {
    .preconfirm-message-container {
        padding: 1.2rem;
    }
    .preconfirm-message-container h2.success,
    .preconfirm-message-container h2.error {
        font-size: 1.4rem;
    }
    .preconfirm-message-container p {
        font-size: 0.9rem;
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/PreConfirmPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,yBAAyB;IACzB,gCAAgC;IAChC,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI;QACI,eAAe;IACnB;IACA;;QAEI,iBAAiB;IACrB;IACA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".preconfirm-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 100vh;\n    background-color: #f4f4f9;\n    font-family: 'Arial', sans-serif;\n    padding: 15px;\n    box-sizing: border-box;\n}\n\n.preconfirm-message-container {\n    background-color: white;\n    padding: 1.5rem;\n    border-radius: 8px;\n    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    max-width: 500px;\n    width: 100%;\n    margin-bottom: 1.5rem;\n}\n\n.preconfirm-message-container h2.success {\n    color: #4caf50;\n    font-size: 1.6rem;\n    margin-bottom: 0.8rem;\n}\n\n.preconfirm-message-container h2.error {\n    color: #f44336;\n    font-size: 1.6rem;\n    margin-bottom: 0.8rem;\n}\n\n.preconfirm-message-container p {\n    font-size: 1rem;\n    color: #555;\n    line-height: 1.5;\n    margin-bottom: 1.5rem;\n}\n\n.loader {\n    font-size: 1.2rem;\n    color: #666;\n    text-align: center;\n    margin: 2rem 0;\n}\n\n@media screen and (max-width: 768px) {\n    .preconfirm-message-container {\n        padding: 1.2rem;\n    }\n    .preconfirm-message-container h2.success,\n    .preconfirm-message-container h2.error {\n        font-size: 1.4rem;\n    }\n    .preconfirm-message-container p {\n        font-size: 0.9rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
