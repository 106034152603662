// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f9;
}

.confirm-message-container {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 600px;
    width: 90%;
    margin-bottom: 2rem;
}

.button-container {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1.5rem;
}

.confirm-message-container h2.success {
    color: #4caf50;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.confirm-message-container h2.error {
    color: #f44336;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.confirm-message-container p {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ConfirmPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;IACxC,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".confirm-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    background-color: #f4f4f9;\n}\n\n.confirm-message-container {\n    background-color: white;\n    padding: 2rem;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    text-align: center;\n    max-width: 600px;\n    width: 90%;\n    margin-bottom: 2rem;\n}\n\n.button-container {\n    display: flex;\n    gap: 1rem;\n    justify-content: center;\n    margin-top: 1.5rem;\n}\n\n.confirm-message-container h2.success {\n    color: #4caf50;\n    font-size: 2rem;\n    margin-bottom: 1rem;\n}\n\n.confirm-message-container h2.error {\n    color: #f44336;\n    font-size: 2rem;\n    margin-bottom: 1rem;\n}\n\n.confirm-message-container p {\n    font-size: 1.2rem;\n    color: #333;\n    margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
