import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import TiersForm from '../components/TiersForm';
import TiersSummary from '../components/TiersSummary';
import './PreConfirmPage.css';

function PreConfirmPage() {
    const [status, setStatus] = useState('');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [formStatus, setFormStatus] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const confirmEmail = async () => {
            const params = new URLSearchParams(location.search);
            const paramsStr = params.toString();

            try {
                const response = await fetch(`/preconfirm?${paramsStr}`);
                const responseData = await response.json();
                setData(responseData.data);
                setStatus(responseData.status);
            } catch (error) {
                console.error('Error during email confirmation:', error);
                setStatus('error');
            } finally {
                setLoading(false);
            }
        };

        confirmEmail();
    }, [location.search]);

    const handleFormSubmitSuccess = (isSuccess) => {
        setFormStatus(isSuccess ? 'success' : 'error');
    };

    return (
        <div className="preconfirm-page">
            {loading ? (
                <div className="loader"><h2>Chargement...</h2></div>
            ) : (
                <div className="preconfirm-message-container">
                    <h2 className={status === 'success' ? 'success' : 'error'}>
                        {status === 'success' ? 'Votre vérification d\'email est terminée' : 'Échec de la vérification de l\'email'}
                    </h2>
                    {data && (
                        <>
                            {data.contact_id === "-1" ? (
                                <>
                                    <p>Veuillez maintenant remplir les informations de facturation pour compléter la réservation.</p>
                                    <TiersForm onSubmitSuccess={handleFormSubmitSuccess} />
                                </>
                            ) : (
                                <TiersSummary data={data.thirdparty} onSubmitSuccess={handleFormSubmitSuccess} />
                            )}
                        </>
                    )}
                    {formStatus === 'success' && (
                        <p className="success">Merci ! Votre pré-réservation est maintenant complète. Vous recevrez un e-mail avec toutes les informations concernant votre rendez-vous une fois la réservation confirmée.</p>
                    )}
                    {formStatus === 'error' && (
                        <p className="error">Erreur lors de l'envoi des informations. Veuillez réessayer.</p>
                    )}
                </div>
            )}
            {!loading && (
                <Button text="Retour à l'outil de réservation" buttonDisabled={false} type={"button"} onClick={() => navigate("/")} />
            )}
        </div>
    );
}

export default PreConfirmPage;
